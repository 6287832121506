import api from './api';

// meeting room
export function postBilling(formData){
    return api.post(`/billing/api`, formData).then(res => res.data);
}

export function create(body){
    return api.post(`/billing/api/create`, body).then(res => res.data);
}

export function psbproses(id, body){
    return api.post(`/billing/api/psb-proses/${id}`, body).then(res => res.data);
}


export function putBilling(id, formData){
    return api.put(`/billing/api/edit/${id}`, formData).then(res => res.data);
}

export function getBilling(keyword, limit, page){
    return api.get(`/billing/api?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteBilling(id){
    return api.delete(`/billing/api/${id}`).then(res => res.data)
}

// import file
export function postImportPelanggan(formData){
    return api.post(`/billing/api/import-pelanggan`, formData).then(res => res.data);
}

// save import file
export function postSaveImportPelanggan(formData){
    return api.post(`/billing/api/import-pelanggan/save`, formData).then(res => res.data);
}

export function prosesAktivasi(id){
    return api.put(`/billing/api/proses-aktivasi/${id}`).then(res => res.data);
}

export function prosesAktivasiNew(id, formData){
    return api.put(`/billing/api/proses-aktivasi/${id}`, formData).then(res => res.data);
}