import { createSlice } from '@reduxjs/toolkit';
import * as wilayahService from '../services/wilayahService';


export const initialState = {
    loading : false,
    hasErrors : false,
    provinsis: [],    
    detail : {},    
}


const provinsiStoreSlice = createSlice({
    name: 'provinsiStore',
    initialState,
    reducers : 
        {               
            getPosts: (state) => {
                state.loading = true
            },
            getPostsSuccess: (state, action) => {                
                state.provinsis = action.payload.data
                state.loading = false
                state.hasErrors = false
            },
            getPostsFailure : (state) => {
                state.loading = false
                state.hasErrors = true
            },
            setDetail : (state, {payload}) => {                
                state.detail = payload;
                state.loading = false;
                state.hasErrors = false;
            }
        }
})



// Three actions generated from the slice
export const {getPosts, getPostsSuccess, getPostsFailure, setDetail} = provinsiStoreSlice.actions;

// A selector
export const provinsiSelector = (state) => state.provinsis
// export const postSelector = (state) => state.posts

// The reducer
export default provinsiStoreSlice.reducer;


// Asyncrounus thunk action 
export function fetchPosts(page, q=''){
    return async (dispatch) => {
        dispatch(getPosts());
        try{
            const response = await wilayahService.getProvinsi(`q=${q}`, 10, page);
            const data = await response;
            console.log('res', data)
            if(data.sukses === 'yes'){
                dispatch(getPostsSuccess(data));                
            }else{
                dispatch(getPostsFailure());
            }            
        }catch(error){
            dispatch(getPostsFailure());
        }
    }
}

// thunk
export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getPosts());
        try{
            const response = await wilayahService.getProvinsi(`id=${id}`, 1, 1);
            const data = await response;
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data));
            }else{
                dispatch(getPostsFailure());
            }                                
        }catch(error){
            dispatch(getPostsFailure());
        }
    }
}


// thunk
export function fetchProvinsiAll(){
    return async(dispatch) => {
        dispatch(getPosts());
        try{
            const response = await wilayahService.getProvinsi(`tipe=all`, 10, 10);
            const data = await response;
            if(data.sukses === 'yes'){
                dispatch(getPostsSuccess(data.data));                
            }else{
                dispatch(getPostsFailure());
            }
        }catch(error){
            dispatch(getPostsFailure());
        }
    }
}
