import api from './api';


//  produk
export function postSatuan(formData){
    return api.post(`/produk/api/satuan`, formData).then(res => res.data);
}

export function putSatuan(id, formData){
    return api.put(`/produk/api/satuan/${id}`, formData).then(res => res.data);
}

export function getSatuan(keyword, limit, page){
    return api.get(`/produk/api/satuan?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteSatuan(id){
    return api.delete(`/produk/api/satuan/${id}`).then(res => res.data)
}


